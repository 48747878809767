import React from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import bannerImg from '../../../images/sharif-hossain-image.jpeg';
import Tooltip from '@mui/material/Tooltip';
 
const AboutMain = () => {
    useTitle('Sharif Hossain | About');

    return (
        // <div>
        //     <hr />
        //     <Typography sx={{ m: 3, color: '#2b6777', fontWeight: 600 }} variant="h4" component="div">
        //         About Me
        //     </Typography>
        //     <Typography sx={{ m: 3, color: '#2b6777', fontWeight: 400 }} variant="h6" component="div">
        //         Coming soon...
        //     </Typography>
        //     <hr />
        // </div>

        <div id=''>
            {/* <hr /> */}
            <Typography sx={{ m: 3, mb:5, color: '#2b6777', fontWeight: 600 }} variant="h4" component="div">
                 About Md Sharif Hossain
            </Typography>
            <img style={{ width: '20%', borderRadius: '50%' }} src={bannerImg} alt="" />
            <br/> <br/>
            <Typography sx={{ mx: 3, mb:3, color: '', fontWeight: 600 }} variant="h6" component="div">
                 <i>"Successful people are not gifted; they just work hard, then succeed on purpose". 
                 <br/>—G.K. Nielson</i>
            </Typography>
            {/* <Typography sx={{ mx: 3, mb:2, color: '', fontWeight: 500 }} variant="h6" component="div">
                Responsible | Optimal | Innovative | Problem Solver | Leader
            </Typography> */}
            <Typography sx={{ mx: 10, mb:5, color: '', fontWeight: 400 }} variant="body1" component="div">
                A highly motivated individual with a strong background and
                profound passion for programming, data science, and Machine
                Learning. Currently working in the Information Technology (IT) industry for more
                than 3 years. Owns several certifications in technical skills outside
                of the curriculums. Entrepreneur of an dormant E-Commerce
                service. Strongly interested in pursuing a Master of Science (MS)
                in Computer Science with research opportunities. Aspires to
                further enrich research knowledge, experience and skills in the
                data-driven areas to make a lasting impact in the relevant
                industry.
            </Typography>
            <Typography sx={{ mx: 10, mb:5, color: '', fontWeight: 400 }} variant="body1" component="div">
                <i>[Note: Please hover on every title for details (if available)]</i> 
            </Typography>
            <Container sx={{width: 'auto'}}>
                <Box sx={{ flexGrow: 1, mb: 0 }}>
                    <Grid container spacing={2}>
                        <Grid xs={8} md={8}>
                            <Box>
                                <Card variant="outlined">
                                    <CardContent>
                                        {/* <Tooltip title="Click for details">
                                            
                                        </Tooltip> */}
                                        {/* <Tooltip title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.">
                                            <Typography variant="h5" component="div">
                                                Executive IT Engineer
                                            </Typography>
                                        </Tooltip> */}
                                        <Tooltip
                                            title={
                                                <React.Fragment>
                                                <ul style={{ margin: 0, padding: '0 0 0 20px' }}> {/* Adjust padding and margin as needed */}
                                                    <li>Upcoming.</li>
                                                    {/* <li>Implementing, training and maintaining industrial ERP, sales force tracking software, VAT and HR software, and secondary sales software.</li>
                                                    <li>Data Analysis, problem solving and generating BI reports.</li> */}
                                                </ul>
                                                </React.Fragment>
                                            }
                                            >
                                            <Typography variant="h5" component="div">
                                                Senior Executive- IT Solutions
                                            </Typography>
                                        </Tooltip>

                                        <Typography sx={{ mb: 1 }} color="text.secondary">
                                            Pandughar Group
                                        </Typography>
                                        <Typography variant="body2">
                                            2024 to Present
                                        </Typography>
                                        <Typography variant="body2">
                                            |
                                        </Typography>
                                        <Tooltip
                                            title={
                                                <React.Fragment>
                                                <ul style={{ margin: 0, padding: '0 0 0 20px' }}> {/* Adjust padding and margin as needed */}
                                                    <li>Developing, debugging and maintaining web and ecommerce platforms.</li>
                                                    <li>Implementing, training and maintaining industrial ERP, sales force tracking software, VAT and HR software, and secondary sales software.</li>
                                                    <li>Data Analysis, problem solving and generating BI reports.</li>
                                                </ul>
                                                </React.Fragment>
                                            }
                                            >
                                            <Typography variant="h5" component="div">
                                                Executive IT Engineer
                                            </Typography>
                                        </Tooltip>

                                        <Typography sx={{ mb: 1 }} color="text.secondary">
                                            Bengal Group of Industries
                                        </Typography>
                                        <Typography variant="body2">
                                            2022 to 2024
                                        </Typography>
                                        <Typography variant="body2">
                                            |
                                        </Typography>

                                        {/* <Typography variant="h5" component="div">
                                            Intern
                                        </Typography> */}
                                        <Tooltip
                                            title={
                                                <React.Fragment>
                                                <ul style={{ margin: 0, padding: '0 0 0 20px' }}> {/* Adjust padding and margin as needed */}
                                                    <li> Java-Based Microservice Solutions Development.</li>
                                                    <li>Technologies: Java, Angular 11, Spring Boot, Oracle 11g/12g, JPA, Hibernate, GitLab, Jasper Report.</li>
                                                </ul>
                                                </React.Fragment>
                                            }
                                            >
                                            <Typography variant="h5" component="div">
                                                Intern
                                            </Typography>
                                        </Tooltip>
                                        <Typography sx={{ mb: 1 }} color="text.secondary">
                                            MySoft Summer-21 vInternship at eSRD Lab, BUET
                                        </Typography>
                                        <Typography variant="body2">
                                            2021
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                        <Grid xs={4} md={4}>
                            <Box>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Profession
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                        <Grid xs={4} md={4}>
                            <Box>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Academic
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                        <Grid xs={8} md={8}>
                            <Box>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            BS in Computer Science and Engineering
                                        </Typography>
                                        <Typography sx={{ mb: 1 }} color="text.secondary">
                                            North South University (NSU)
                                        </Typography>
                                        <Typography variant="body2">
                                            Graduated in 2021
                                        </Typography>
                                        <Typography variant="body2">
                                            |
                                        </Typography>
                                        <Typography variant="h5" component="div">
                                            Higher Secondary School
                                        </Typography>
                                        <Typography sx={{ mb: 1 }} color="text.secondary">
                                            Dhaka City College
                                        </Typography>
                                        <Typography variant="body2">
                                            {/* Session 2014-15 */}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Button variant="contained" style={{ backgroundColor: '#253746' }} sx={{ mt: 2, mb: 3, textDecoration: 'none' }}>
                    <Link to={'/'} style={{ textDecoration: 'none', color: 'white' }}>Home</Link>
                </Button>
            </Container>
        </div>
    );
};

export default AboutMain;